
    .cta{
        &.white{
            text-transform: uppercase;
            color: $violet;
            padding: 15px 30px;
            background: #FAF8FE;
            font-size: 18px;
            border-radius: 4px;
            font-weight: 500;
        }   
    }   


    .input-wrapper{
        &.contact-input{
            label{
                display: block;
                color: $navy;
            }
            input[type=text]{
                display: inline-block;
                border: none;
                border-top: solid 1px $navy;
                border-left: solid 1px $navy;
                border-bottom: solid 1px $navy;
                color: $navy;
                background: white;
                height: 45px;
                width: 250px;
                font-size: 16px;
                padding-left: 5px;
                &:focus{
                    outline: none;
                }
            }
            button{
                text-transform: uppercase;
                height: 45px;
                width: 100px;
                border: solid 1px $navy;
                color: $navy;
                font-weight: 600;
                font-size: 16px;
                background: $soft-purple; 
                &:hover{
                    color: $purple;
                    cursor: pointer;
                }
            }
            p{
                margin-top: 8px;
            }
        }
    }

    @mixin placeholder-style{
        color: black;
        font-style: italic;
        opacity: 0.4;
    }

    ::-webkit-input-placeholder { /* Edge */
        @include placeholder-style;
    }
      
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        @include placeholder-style;
    }
      
    ::placeholder {
        @include placeholder-style;
    }