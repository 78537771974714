$right-left-col: rgb(250,250,250);
$main-border-color: rgb(220,220,220);


$on-cell: rgba(33,107,255, 0.2);
$off-cell: rgba(33,107,255, 0.4);

.table-wrapper{
    background: rgb(250,250,250);
    margin-bottom: 20px;
    padding-top: 25px;
    .table-container {
        min-height: 600px;
        height: calc(100vh - 300px);
        width: 98%;
        margin: 0px 20px;
        overflow: scroll;
        position: relative;
        // min-width: 650px;
    }
}


.configuration-container{
    width: 100%;
    background: rgb(245,245,249);
    padding: 40px 30px 5px 30px;
    box-shadow: inset 0px -3px 10px rgb(225,225,225);

    h2,label,input{
        font-family: 'Proxima Nova Rg';
    }

    h2{
        color: $navy;
        font-size: 22px;
        padding-left: 10px;
        margin-bottom: 20px;
        border-left: solid 5px $navy;
        text-transform: uppercase;
    }


    .advanced-button{
        display: inline-block;
        padding: 8px 10px;
        border: solid 1px #ABABAB;
        border-radius: 4px;
        background: white;
        margin: 30px 0 5px 0;
        h3{
            font-size: 15px;
            margin: 0;
        }
        &:hover{
            cursor: pointer;
        }
    }

    .input-container{
        width: 250px;
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 20px;
        label{
            display: block;
            color: $navy;
            font-size: 15px;
        }
        .text-input{
            appearance: none;
            border: solid 1px #e3e3e3;
            background: white;
            height: 40px;
            width: 100%;
            color: $navy;
            padding-left: 5px;
            font-size: 14px;
        }
        .select-input{
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNS41IDVMMTAgMSIgc3Ryb2tlPSIjMTE4OUY3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%;
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;
            padding-right: 20px;
            width: 100%;
            border-radius: 0;
            border: solid 1px #e3e3e3;
            height: 40px;
            color: $navy;
            background-color: white;
            padding-left: 5px;
            font-size: 14px;
        }
        &.budget-input{
            width: 250px;
            .budget-input-wrapper{
                display: flex;
                align-items: center;
                .currency-input{
                    width: 20%;
                }
                .text-input{
                    width: 40%;
                    border-left: 0;
                    border-right: 0;
                }
                .frequency-input{
                    width: 40%;
                }
            }
            
        }
    }
    h3{
        display: inline-block;
    }

    .arrow-wrapper{
        display: inline-block;
        margin-left: 5px;
        &.rotated-arrow{
            -ms-transform: rotate(90deg); /* IE 9 */
            transform: rotate(90deg);
        }
    }
    
    .advanced-inputs{
        display: none;
        margin-top: 10px;
        margin-bottom: 20px;
        align-items: flex-start;
        flex-wrap: wrap;
        &.visible{
            display: flex;
        }
    }
    .learn-scores{
        font-size: 13px;
        color: $purple;
        width: 100%;
        &:hover{
            text-decoration: underline;
        }
    }
    .disclaimer{
        font-size: 13px;
        margin-bottom: 0;
    }
}




table.all-vpns{
    table-layout: fixed;
    width: 100%;
    
    position: relative;
    border-collapse: collapse;
    // border-spacing: 0 10px;
    vertical-align: center;
    text-align: center;
    font-family: $tertiary-font-family;

    thead{
        position: relative;
        z-index: 150;
        tr{
            position: relative;
            z-index: 150;
            th{
                position: -webkit-sticky; /* for Safari */
                position: sticky;

                top: 0;
                text-align: right;
                background-color: $right-left-col;
                color: black;
                font-size: 14px;
                font-family: 'Univia Pro';
                z-index: 120;
                word-wrap: break-word;
                border-right: none;
                // box-shadow: inset 0px -2px 0px black;
                vertical-align: top;
                padding: 15px 0px 25px 13px;
                // &:not(:first-child){
                //     box-shadow: 7px 4px 6px rgba(40,40,40,0.5);
                // }

                &:first-child {
                    padding-left: 10px;
                    left: 0;
                    border-right: none;
                    z-index: 151;
                    text-align: left;
                }
                &:hover{
                    cursor: pointer;
                }

                .tooltip-wrapper{
                    position: relative;
                }
            }
        }   
    }

    td,th {
        vertical-align: middle;
        height: 55px;
        width: 120px;
    }

    

    tbody{
        position: relative;
        z-index: 5;
        font-size: 14px;
        th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 0;
            text-align: left;
            z-index: 80;
            border-top: solid 2px darken($soft-purple, 4%);
            border-left: solid 2px darken($soft-purple, 4%);
            border-bottom: solid 2px darken($soft-purple, 4%);
            padding-left: 10px;
            background-color: rgb(245,245,249);
            font-size: 15px;
            border-right: none;
            color: $navy;
        }
        th:not(:first-child){
            text-decoration: underline;
        }
        tr:nth-child(odd) {
            background-color: white;
            th{
                background-color: white;
            }
        }
    
        tr{
            height: 60px;
            font-weight: 300;
            td{
                border-top: solid 2px darken($soft-purple, 4%);
                border-bottom: solid 2px darken($soft-purple, 4%);
                text-align: right;
                padding-left: 5px;
                color: $navy; 
                font-size: 18px;

                .annual-extra{
                    font-size: 12px;
                }
                
                &:nth-child(2){
                    text-align: right;
                    color: $purple;
                    border-left: none;
                }
                // &:first-child{
                   
                // }
                &:last-child{
                    text-align: center;
                    // background: $right-left-col;
                    position: -webkit-sticky; /* for Safari */
                    position: sticky;
                    right: 0;
                    z-index: 80;
                    font-size: 14px;

                }

                .three-tier{
                    width: 22px;

                    &.good{
                        color: #3CBE15;
                    }
                    &.ok{
                        color: #FF9900;
                    }
                    &.bad{
                        color: #FF5252;
                    }
                }
                .score-circle{
                    width: 32px;
                    height: 32px;
                    display: inline-block;
                    border-radius: 50%;
                }     
                .ones{
                    background: #fe9b9b;
                    color: #951919;
                    
                    span{
                        position: relative;
                        top: 4px;
                        right: 15px;
                    }
                }
                .twos{
                    color: #906422;
                    background-color: #fec878;
                    span{
                        position: relative;
                        top: 4px;
                        right: 11px;
                    }
                }
                .threes{
                    background-color: #baf6a8;
                    color: #2E6F1B;
                    span{
                        position: relative;
                        top: 4px;
                        right: 11px;
                    }
                }
            }
            &:nth-child(even){
                // background-color: $soft-purple;
                background-color: rgb(245,245,249);
                &:hover{
                    // background-color: darken($faint-navy, 1%);
                }
            }
            &:nth-child(odd){
                background-color: white;
                &:hover{
                    // background-color: darken(white, 1%);
                }
            }
            &:hover{
                cursor: pointer;
                // background-color: rgb(252,252,252);
                .cta{
                    opacity: 1;
                }
            }
        }

        .cta{
            background: $soft-purple;
            width: 80px;
            padding: 7px 12px;
            border-radius: 4px;
            height: 30px;
            box-shadow: 0px 1px 7px rgb(210,210,210);
            text-decoration: none;
            font-size: 16px;
            opacity: 0;
            border: solid 1px $purple;

            -webkit-transition: all 0.3s ease-in;;
            transition: all 0.3s ease-in;;

            &:hover{
                background: $purple;
                span{
                    color: white;
                }
            }
        }
        .cta span{
            height: 30px;
            color: $purple;

        }
    }
}

.modal-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    .logo-container{
        text-align: center;
    }

    .modal-headline{
        background-color: $purple;
        padding: 20px 10%;
        margin-bottom: 100px;
        height: 130px;
        .modal-headline-content-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 36px;

            img{
                width: 125px;
            }
            .modal-logo-wrapper{
                width: 150px;
                height: 150px;
                border-radius: 75px;
                background: #FBFBFB;
                display: flex;
                align-items: center;
                flex-direction: column;
                overflow: hidden;
                justify-content: center;
            }
            .modal-headline-score{
                color: $purple;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 150px;
                height: 150px;
                min-width: 150px;
                min-height: 150px;
                background: #FBFBFB;
                border-radius: 50%; 
                h2{font-size: 35px;}
                span{
                    font-weight: 500;
                    font-size: 20px;
                }
            }
        }
        
    }
}

.ReactModal__Overlay{
    z-index: 99999;
}
.ReactModal__Content{
    
    &.ReactModal__Content--after-open{
        padding: 0 !important;
    }
    .custom-shape-divider-bottom-1597429298 {
        margin-bottom: 40px;
    }
} 
.custom-shape-divider-bottom-1597429298 {
    .shape-fill {
        fill: #F8F7FB;
    }
}



// .whichvpn-score{
//     padding: 5px 0px;
//     font-weight: 300;
//     background: $purple;
//     color: white;
//     text-align: center;
//     font-size: 16px;
//     margin-top: 60px;
// }
.table-pad{
    padding: 0px 20px 0px 20px;
    overflow-y: scroll;
    height: 30%;
    max-height: 30%;
    min-height: 30%;
}
table.vpn-detail-table{
    width: 100%;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    border-collapse: collapse;
    tbody{
        tr{
            height: 40px;
            padding: 8px 0px;
            &:nth-child(odd){
                background-color: #FBFBFB;
            }
            th{
                padding-left: 10px;
                width: 50%;
                text-align: left;
                color: $navy;
                font-weight: 400;
                .more-info-tt{
                    margin-left: 15px;

                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            td{
                font-weight: 600;
                color: $navy;
                padding: 10px;
                width: 50%;
                text-align: right;
            }
        }
    }
}

.app-store-ratings{
    display: flex;
    border: solid 1px rgb(220,220,220);
    background: #FBFBFB;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 10px;
 
    .ratings-table-title{
        width: 100%;
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
    }

    .image-wrapper{
        margin-bottom: 20px;
        width: 25%;
        text-align: center;
    }
    span{
        width: 25%;
        text-align: center;
        height: 35px;
        font-size: 18px;
    }
    
}

.ratings-table{
    width: 100%;
    margin: 30px 0px;
    border: solid 1px rgb(220,220,220);
    font-family: $secondary-font-family;
    background: rgb(245,245,245);
    thead{
        font-size: 22px;
        text-align: center;
        tr{
            td{
                padding: 18px 0;
            }
        }
    }
    tbody{
        text-align: center;
        tr{
            th{
                width: 25%; 
            }
            td{
                font-size: 18px;
                &:first-child{
                    padding: 10px 0px;     
                }
            }   
        }
    }
}
.vpn-detail-cta-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #FAFAFA;
    box-shadow: 0px -4px 6px rgb(230,230,230);
    padding: 25px 25px 0px 25px;
    p {
        flex-grow: 1;
        font-size: 14px;
        margin-top: 18px;
    }
    .cancel{
        appearance: none;
        background: none;
        font-size: 18px;
        border: none;
        padding-left: 0;
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .vpn-detail-sign-up{
        color: $purple;
        font-family: $secondary-font-family;
        font-size: 20px;
        font-weight: 500;
        border: solid 1px $purple;
        padding: 7px 18px;
        border-radius: 6px;
        &:hover{
            background: lighten($purple, 45);
        }
    }
}



.tooltip-wrapper{
    position: relative;
    display: inline;
    top: 2px;
    margin-left: 5px;

    .tooltip-marker{
        width: 20px;
        height: 20px;
        display: inline-block;
        color: black;
        border-radius: 50%;
        text-align: center;
        &:hover{
            cursor: pointer;
            &~.tooltip-placer{
                .tooltip{
                    display: inline-block; 
                    z-index: 98723482736;
                }
            }
        }
    }
    .tooltip-placer{
        display: inline-block;
        position: relative;
        width: 1px;
        height: 1px;
        max-width: 1px;
        max-height: 1px;
    }

    .tooltip{
        background: white;
        padding: 10px 12px;
        border: solid 1px rgb(200,200,200);
        display: none;
        position: absolute;
        font-weight: 200;
        bottom: -50px;
        width: 215px;
        font-size: 14px;
        left: 0;
    }
}

.ReactModal__Overlay{
    background-color: rgba(225, 225, 225, 0.75);
    backdrop-filter: blur(3px);
}

@media only screen and (max-width: $med-screen-width) {
    .ReactModal__Content{
        &.ReactModal__Content--after-open{
            min-width: 95% !important;
            width: 95% !important;

            table{
                &.vpn-detail-table{
                    font-size: 16px;
                }
            }


            .modal-content{ 
                .modal-headline{
                    .modal-headline-content-wrapper{ 
                        top: 50px;
                        .modal-logo-wrapper{
                            width: 120px;
                            height: 120px;
                            border-radius: 60px;
                            img{
                                width: 110px;
                            }
                        }
                        .modal-headline-score{
                            width: 120px;
                            height: 120px;
                            min-width: 120px;
                            min-height: 120px;
                            border-radius: 60px; 
                            span{
                                font-weight: 500;
                                font-size: 16px;
                            }
                        }
                    }
                    
                }
            }


        }
    }
}