header{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    box-shadow: 0px 2px 11px rgb(200,200,200);
    align-items: center;
    position: relative;
    z-index: 999;
    background: white;

    img{
        width: 150px;
    }

    nav{
        display: inline;
        font-family : 'Univia Pro', sans-serif;
        ul{
            list-style-type: none;
            li{
                display: inline;
                margin-left: 50px;
                font-size: 16px;
                a{
                    color: black;
                    font-family: 'Univia Pro', sans-serif;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 500;
                    &:visited{
                        color: black;
                        text-decoration: none;
                    }
                    &:hover{
                        color : $purple;
                    }
                }

                &:hover{
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
    .mobile-nav-button{
        color: black;
        background: white;
        border-radius: 4px;
        display: none;
        button{
            background: white;
            font-size: 14px;
            padding: 10px 20px;
            border-radius: 4px;
            border: solid 1px rgb(210,210,210);
            &:hover{
                cursor: pointer;
            }
        }
    }
    .mobile-nav{
        position: fixed;
        z-index: 99999999;
        width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        background: white;
        color: black;
        flex-direction: column;
        align-items: center;
        padding: 30px 25px;
        .mobile-logo-cancel{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            
            .cancel{
                width: 30px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        
        &.show{
            display: flex;
        }
        &.hide{
            display: none;
        }
        .mobile-nav-item{
            margin-bottom: 20px;
            font-size: 22px;
            a{
                font-family : 'Univia Pro', sans-serif
            }
        }
        ul{
            margin-top: 50px;
            padding-left: 0;
            list-style-type: none;
        }
    }
}

@media only screen and (max-width: $med-screen-width) {

    header{
        padding: 10px 20px;
        nav{
            display: none;
            // width: 100%;
            // margin: 10px 0px;
            // ul{
            //     width: 100%;
            //     padding-left: 10px;
            //     margin-bottom: 0;
            //     margin-top: 0;
            //     li{
            //         margin-left: 16px;
            //         font-size: 14px;
            //     }
            // }
        }
        .mobile-nav-button{
            display: inline-block;
        }
    }


}