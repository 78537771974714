.about{
    width: $med-screen-width;
    margin: 0 auto;
    padding: 80px 0px;

    h2{
        margin-bottom: 40px;
        color: $purple;
        font-size: 32px;
    }
    ul{
        list-style: none;
        color: $navy;
        li{
            margin-top: 30px;
            color: $navy;
            &::before{
                content: "\2022";
                display: inline;
                font-size: 25px;
                color: $purple;
                margin-right: 14px;
            }
            h4{
                display: inline;
            }
        }
    }
    h3{
        color: $navy;
        margin-top: 30px;
    }
    p{
        color: $navy;
    }
}

@media only screen and (max-width: $med-screen-width) {
    .about{
        width: 100%;
        padding: 40px 20px;
    }
}