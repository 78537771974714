.hero{
    background: linear-gradient(82.2deg, $violet 2.66%, $purple 50.72%, $blue 100%);
    color: white;
    display: flex;
    min-height: 700px;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .content-wrapper{
        margin-top: -125px;
        z-index: 9999;
        h1{
            font-size: 50px;
            font-weight: 500;
            width: 60%;
            margin-bottom: 12px;
        }
        h2{
            font-size: 26px;
            font-weight: 300;
            opacity: 0.8; 
            margin-bottom: 40px;
        }
    }

    .hero-image{
        position: absolute;
        right: 0;
    }
    
    .custom-shape-divider-bottom-1597429298 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }
    
    .custom-shape-divider-bottom-1597429298 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 125px;
    }
    
    .custom-shape-divider-bottom-1597429298 .shape-fill {
        fill: #FFFFFF;
    }
}

.intro{
    margin: 150px 0px;
    .content-wrapper{
        .intro-content{
            display: flex;
            color: $navy;
            align-items: center;
            .intro-text{
                width: 60%;
                h3{
                    font-weight: 400;
                    font-size: 30px;
                }
                p{
                    font-size: 18px;
                    width: 60%;
                }
            }
            .intro-image{
                text-align: center;
                width: 40%;
            }
        }
    }
}

.blog-sample{
    background: $soft-purple;
    padding: 90px 0px;
    h3{
        font-weight: 400;
        font-size: 30px;
        color: $navy;
        margin-bottom: 40px;
    }
    .content-wrapper{
        .tile-holder{
            display: flex;
            // justify-content: space-between;
            a{
                width: 28%;
                margin-right: 5%;
                .tile{
                    background: rgb(245,245,245);
                    width: 100%;
                    height: 400px;
                    
                    position: relative;
                    transition: all 0.4s;
                    -webkit-transition: all 0.4s;
        
                    .tile-title{
                        background: white;
                        width: 100%;
                        padding: 15px 25px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        h4{
                            font-size: 19px;
                            font-weight: 400;
                            color: $purple;
                            transition: all 0.4s;
                            -webkit-transition: all 0.4s;
                        }
                    }
                    
                    &:hover{
                        cursor: pointer;
                        box-shadow: 0px 0px 14px rgba(113,113,113,0.25);
                        // .tile-title{
                        //     h4{
                        //         font-weight: 600;
                        //     }    
                        // }
                    }
                }
            }
        }
    }
}

.contact{
    padding: 150px 0px;
    .content-wrapper{
        .contact-content{
            display: flex;

            .contact-image{
                width: 40%;
                text-align: center;
            }
            .contact-text{
                width: 60%;
                color: $navy;
                h3{
                    font-weight: 400;
                    font-size: 30px;
                    margin-bottom: 10px; 
                }
            }
        }
    }
}


@media only screen and (max-width: $med-screen-width) {
    .hero{
        .content-wrapper{
            h1{
                width: 90%;
                padding-left: 20px;
                font-size: 35px;
            }
            h2{
                padding-left: 20px;
                font-size: 18px;
            }
            .cta{
                margin-left: 20px;
            }
        }
    }

    .intro{
        margin: 100px 0px;
        .content-wrapper{
            .intro-content{
                flex-direction: column-reverse;
                align-items: center;
                .intro-text{
                    width: 90%;
                    h3{
                       
                    }
                    p{
                        width: 80%;
                    }
                }
                .intro-image{
                    margin-bottom: 70px;
                    width: 100%;
                }
            }
        }
    }

    .blog-sample{
        padding: 70px 20px;
        .content-wrapper{
            .tile-holder{
                flex-direction: column;
                align-items: center;
                a{
                    width: 100%;
                }
                .tile{
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
    
    .contact{
        padding: 70px 0px;
        .content-wrapper{
            .contact-content{
                align-items: center;
                flex-direction: column;
                .contact-image{
                    margin-bottom: 60px;
                    width: initial;
                }
                .contact-text{
                    width: initial;
                }
            }
        }
    }

}