footer{
    background: $navy;
    color: white;
    padding: 40px 0px;

    .content-wrapper{
        img{
            width: 150px;
        }
        .footer-info{
            display: flex;
            justify-content: space-between;

            .footer-navigation{
                ul{
                    li{
                        list-style-type: none;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                    a{
                        color: white;
                        text-decoration: none;
                        &:visited{
                            color: white;
                            text-decoration: none;
                        }
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                
            }
            .official-docs{
                ul{
                    li{
                        list-style-type: none;
                        margin-bottom: 10px;
                        font-size: 14px;

                        a{
                            color: white;
                            text-decoration: none;
                            &:visited{
                                color: white;
                                text-decoration: none;
                            }
                            &:hover{
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .social-links{
                img{
                    width: 30px;
                    margin-right: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: $med-screen-width) {
    footer{
        padding: 40px 20px;
        .content-wrapper{
            .footer-info{
                flex-direction: column;
                ul{
                    padding-left: 0;
                }
            }
        }
    }
}