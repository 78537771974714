.error-wrapper{
	
}

.app-loading{
	
}

.content-wrapper{
    width: 1200px;
    margin: 0 auto;
}


@media only screen and (max-width: $max-screen-width) {
    .content-wrapper{
        width: 900px;
    }
}
@media only screen and (max-width: $med-screen-width) {
    .content-wrapper{
        width: 600px;
    }
}
@media only screen and (max-width: $min-screen-width) {
    .content-wrapper{
        width: 100%;
    }
}
