@font-face {
    font-family: 'Univia Pro Ultra';
    src: url('./fonts/univiapro/UniviaPro-UltraItalic.eot');
    src: local('Univia Pro Ultra Italic'), local('UniviaPro-UltraItalic'),
        url('./fonts/univiapro/UniviaPro-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-UltraItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-UltraItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-UltraItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro Book';
    src: url('./fonts/univiapro/UniviaPro-Book.eot');
    src: local('Univia Pro Book'), local('UniviaPro-Book'),
        url('./fonts/univiapro/UniviaPro-Book.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Book.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Book.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro Book';
    src: url('./fonts/univiapro/UniviaPro-BookItalic.eot');
    src: local('Univia Pro Book Italic'), local('UniviaPro-BookItalic'),
        url('./fonts/univiapro/UniviaPro-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-BookItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-BookItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-MediumItalic.eot');
    src: local('Univia Pro Medium Italic'), local('UniviaPro-MediumItalic'),
        url('./fonts/univiapro/UniviaPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-MediumItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-MediumItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro Ultra';
    src: url('./fonts/univiapro/UniviaPro-UltraLight.eot');
    src: local('Univia Pro Ultra Light'), local('UniviaPro-UltraLight'),
        url('./fonts/univiapro/UniviaPro-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-UltraLight.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-UltraLight.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-LightItalic.eot');
    src: local('Univia Pro Light Italic'), local('UniviaPro-LightItalic'),
        url('./fonts/univiapro/UniviaPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-LightItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-LightItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Medium.eot');
    src: local('Univia Pro Medium'), local('UniviaPro-Medium'),
        url('./fonts/univiapro/UniviaPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Medium.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Medium.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Light.eot');
    src: local('Univia Pro Light'), local('UniviaPro-Light'),
        url('./fonts/univiapro/UniviaPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Light.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Light.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Thin.eot');
    src: local('Univia Pro Thin'), local('UniviaPro-Thin'),
        url('./fonts/univiapro/UniviaPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Thin.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Thin.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-ThinItalic.eot');
    src: local('Univia Pro Thin Italic'), local('UniviaPro-ThinItalic'),
        url('./fonts/univiapro/UniviaPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-ThinItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-ThinItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-BoldItalic.eot');
    src: local('Univia Pro Bold Italic'), local('UniviaPro-BoldItalic'),
        url('./fonts/univiapro/UniviaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-BoldItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-BoldItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro Ultra';
    src: url('./fonts/univiapro/UniviaPro-UltraLightItalic.eot');
    src: local('Univia Pro Ultra Light Italic'), local('UniviaPro-UltraLightItalic'),
        url('./fonts/univiapro/UniviaPro-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-UltraLightItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-UltraLightItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Italic.eot');
    src: local('Univia Pro Italic'), local('UniviaPro-Italic'),
        url('./fonts/univiapro/UniviaPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Italic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Italic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro Ultra';
    src: url('./fonts/univiapro/UniviaPro-Ultra.eot');
    src: local('Univia Pro Ultra'), local('UniviaPro-Ultra'),
        url('./fonts/univiapro/UniviaPro-Ultra.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Ultra.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Ultra.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Black.eot');
    src: local('Univia Pro Black'), local('UniviaPro-Black'),
        url('./fonts/univiapro/UniviaPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Black.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Black.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Regular.eot');
    src: local('Univia Pro Regular'), local('UniviaPro-Regular'),
        url('./fonts/univiapro/UniviaPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Regular.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Regular.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-BlackItalic.eot');
    src: local('Univia Pro Black Italic'), local('UniviaPro-BlackItalic'),
        url('./fonts/univiapro/UniviaPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-BlackItalic.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-BlackItalic.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('./fonts/univiapro/UniviaPro-Bold.eot');
    src: local('Univia Pro Bold'), local('UniviaPro-Bold'),
        url('./fonts/univiapro/UniviaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/univiapro/UniviaPro-Bold.woff2') format('woff2'),
        url('./fonts/univiapro/UniviaPro-Bold.woff') format('woff'),
        url('./fonts/univiapro/UniviaPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/proximanova/ProximaNova-SemiboldIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('./fonts/proximanova/ProximaNova-Black.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/proximanova/ProximaNova-Extrabld.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Extrabld.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/proximanova/ProximaNova-Light.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('./fonts/proximanova/ProximaNova-BlackIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/proximanova/ProximaNova-ExtrabldIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-ExtrabldIt.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/proximanova/ProximaNova-Regular.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/proximanova/ProximaNova-RegularIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-RegularIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/proximanova/ProximaNova-BoldIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/proximanova/ProximaNova-LightIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/proximanova/ProximaNova-Bold.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/proximanova/ProximaNova-ThinIt.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-ThinIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/proximanova/ProximaNovaT-Thin.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/proximanova/ProximaNova-Semibold.woff2') format('woff2'),
        url('./fonts/proximanova/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
//Look into using SystemUI/Apple/Android fall back fonts
$primary-font-family : 'Univia Pro', sans-serif;
$secondary-font-family: 'Proxima Nova Rg', sans-serif;
$tertiary-font-family: 'Proxima Nova Lt', sans-serif;

h1,h2,h3,h4,h5{
    margin: 0;
    font-family: $secondary-font-family;
    &.u-title{
        font-family: $primary-font-family;
    }
    &.p-title{
        font-family: $secondary-font-family;
    }
}
h2{
    font-size: 30px;
}
h3{
    font-size: 26px;
}
h4{
    font-size: 20px;
}
p{
    font-size: 16px;
    line-height: 1.4em;
}

p,ul,li,span,a,label,input,button,select{
    font-family: $secondary-font-family
}
a{
    text-decoration: none;
    &:visited{
        text-decoration: none;
    }
}
nav{
    ul{
        li{
            font-weight: 600;
        }
    }    
}